import React from "react";
import HeaderBanner from "../components/HeaderBanner";
import Button from "../components/Button";
import StepByStep from "../components/StepByStep";
import Emoji from "../assets/img/jaja.svg";
import Container from "../components/Container";
import ColorBanner from "../components/ColorBanner";
import Cb1 from "../assets/img/seguros-cb1.svg";
import Cb2 from "../assets/img/seguros-cb2.svg";
import Cb3 from "../assets/img/seguros-cb3.svg";
import Marcas from "../components/Seguros/Marcas";
import TyC from "../components/Seguros/TyC";
import { useHeaderBanner } from "../hooks/useHeaderBanner";

const Seguros = () => {
  const headerBannerData = useHeaderBanner("Seguros");
  const steps = [
    {
      title: "Cotizá",
      subtitle:
        "Cargá los datos de tu auto o moto y dejá todo en nuestras manos.",
    },
    {
      title: "Compará",
      subtitle:
        "Las mejores aseguradoras y coberturas para que puedas elegir la que más se adapte a vos.s",
    },
    {
      title: "Asegurá",
      subtitle: "Seleccioná el medio de pago ¡y listo!",
    },
  ];

  const colorBannerElements = [
    {
      icon: Cb1,
      text: "Contratá <b>100% online</b>",
    },
    {
      icon: Cb2,
      text: "Protegés tu vehículo <b>las 24 horas</b>",
    },
    {
      icon: Cb3,
      text: "Disfrutá de la tranquilidad de <b>manejar seguro</b>",
    },
  ];

  return (
    <>
      <HeaderBanner
        imgPath={headerBannerData.urlFoto}
        italicText={`<span>${headerBannerData.subtitulo}</span><img src='${headerBannerData.urlLogoSubtitulo}' class='hb-icon'/>`}
        title={headerBannerData.titulo}
        description={headerBannerData.copy}
        button={
          <Button variant={"filled-red"}>{headerBannerData.textoBoton}</Button>
        }
        isButtonLeft={false}
        titleColor={"#63BA68"}
      />
      <Container>
        <StepByStep
          color={"green"}
          icon={Emoji}
          subtitle={""}
          title={`¿Todavía no elegiste el plan perfecto para vos?<br /> Chequeá los pasos para poder hacerlo`}
          steps={steps}
        />
      </Container>
      <ColorBanner
        color={"green"}
        title={"¡Cotizá ya!"}
        subtitle={
          "Te contamos lo simple que te resultará sacar tu seguro y con qué beneficios contarás."
        }
        elements={colorBannerElements}
      />
      <Marcas />
      <TyC />
    </>
  );
};

export default Seguros;
