import React, { useState, useEffect } from "react";
import Container from "../Container";
import ArrowLeft from "../../assets/img/arrow-left.png";
import { Rating } from "react-simple-star-rating";
import Scrollable from "../Scrollable";

const ReviewCard = ({ rating, text, author, veredict }) => {
  return (
    <div className="review-card">
      <div className="rating-wrapper">
        <Rating initialValue={rating} allowFraction readonly />
      </div>
      <h6
        className="testimonio"
        dangerouslySetInnerHTML={{ __html: text }}
      ></h6>
      <div className="review-footer">
        <p className="author">{author}</p>
        <span className="veredict">{veredict}</span>
      </div>
    </div>
  );
};

const Reviews = () => {
  const [reviewElements, setReviewElements] = useState([]);
  useEffect(() => {
    const getReviews = async () => {
      const res = await fetch(
        "https://santander-consumer-v2-e0c89957455a.herokuapp.com/api/ratings/list"
      );

      const json = await res.json();
      return json;
    };

    getReviews().then((json) => setReviewElements(json));
  }, []);

  // const reviewElements = [
  //   {
  //     rating: 4.5,
  //     text: "“Muy <b>efectiva, eficaz y el producto que deseas lo tenés en tus manos al momento.</b> Una forma muy fácil de pagar el crédito. Gracias”",
  //     author: "Martín Medina",
  //     veredict: "Compró una televisión en cuotas fijas",
  //   },
  //   {
  //     rating: 4.5,
  //     text: "“Muy <b>efectiva, eficaz y el producto que deseas lo tenés en tus manos al momento.</b> Una forma muy fácil de pagar el crédito. Gracias”",
  //     author: "Martín Medina",
  //     veredict: "Compró una televisión en cuotas fijas",
  //   },
  //   {
  //     rating: 4.5,
  //     text: "“Muy <b>efectiva, eficaz y el producto que deseas lo tenés en tus manos al momento.</b> Una forma muy fácil de pagar el crédito. Gracias”",
  //     author: "Martín Medina",
  //     veredict: "Compró una televisión en cuotas fijas",
  //   },
  //   {
  //     rating: 4.5,
  //     text: "“Muy <b>efectiva, eficaz y el producto que deseas lo tenés en tus manos al momento.</b> Una forma muy fácil de pagar el crédito. Gracias”",
  //     author: "Martín Medina",
  //     veredict: "Compró una televisión en cuotas fijas",
  //   },
  //   {
  //     rating: 4.5,
  //     text: "“Muy <b>efectiva, eficaz y el producto que deseas lo tenés en tus manos al momento.</b> Una forma muy fácil de pagar el crédito. Gracias”",
  //     author: "Martín Medina",
  //     veredict: "Compró una televisión en cuotas fijas",
  //   },
  //   {
  //     rating: 4.5,
  //     text: "“Muy <b>efectiva, eficaz y el producto que deseas lo tenés en tus manos al momento.</b> Una forma muy fácil de pagar el crédito. Gracias”",
  //     author: "Martín Medina",
  //     veredict: "Compró una televisión en cuotas fijas",
  //   },
  // ];

  return (
    <div className="reviews vertical-spacing">
      <h4>Nuestros clientes confían en nosotros</h4>
      <div className="spacer"></div>
      <Container>
        <Scrollable
          arrowLeft={<img src={ArrowLeft} alt="" className="review-arrow" />}
          arrowRight={
            <img src={ArrowLeft} alt="" className="review-arrow reverse" />
          }
          mobileArrowOffset={300}
          arrowOffset={300}
        >
          <div className="review-timeline">
            {reviewElements.map((el, i) => (
              <ReviewCard
                author={el.nombreCliente}
                rating={el.rating}
                text={`“${el.testimonio}”`}
                veredict={el.producto}
                key={i}
              />
            ))}
          </div>
        </Scrollable>
      </Container>
    </div>
  );
};

export default Reviews;
