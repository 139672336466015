import React, { useState } from "react";
import Container from "../Container";
import Chevron from "../../assets/img/chevron-down.svg";

const Accordion = ({ pregunta, respuesta }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className={`accordion ${isOpen ? "open" : ""}`}
      onClick={() => {
        setIsOpen(!isOpen);
      }}
    >
      <div className="a-head">
        <h6>{pregunta}</h6>
        <img src={Chevron} alt="chevron" className="accordion-arrow" />
      </div>
      <div className="a-body">
        <div
          className="body-flex"
          dangerouslySetInnerHTML={{ __html: respuesta }}
        ></div>
      </div>
    </div>
  );
};

const AccordionGroup = ({ elements, title }) => {
  return (
    <div className="ag">
      <Container>
        <h1 className="ag-title">{title}</h1>
        <div className="accordion-group">
          {elements.map((el, i) => (
            <Accordion
              key={i}
              pregunta={el.pregunta}
              respuesta={el.respuesta}
            />
          ))}
        </div>
      </Container>
    </div>
  );
};

export default AccordionGroup;
