import React from "react";
import Container from "./Container";
import FadeAndJump from "./Animations/FadeAndJump";
import BubbleShow from "./Animations/BubbleShow";
import { useElementInView } from "../hooks/useElementInView";
const ColorBannerElement = ({ icon, text, delay, inView }) => {
  return (
    <FadeAndJump inView={inView} className="cbe" delay={delay}>
      <BubbleShow delay={delay} inView={inView}>
        <img src={icon} alt={text} />
      </BubbleShow>
      <p dangerouslySetInnerHTML={{ __html: text }}></p>
    </FadeAndJump>
  );
};

const ColorBanner = ({ color, elements, title, subtitle }) => {
  const { ref, isInView } = useElementInView({ once: true, amount: 0.5 });
  return (
    <>
      <div className="cb-text">
        <h1 className="cb-title">{title}</h1>
        <p className="cb-subtitle">{subtitle}</p>
      </div>
      <div className={`cb ${color}`}>
        <Container>
          <div className="cbe-group" ref={ref}>
            {elements.map((el, i) => (
              <ColorBannerElement
                key={i}
                icon={el.icon}
                text={el.text}
                delay={(i / 10) * 2}
                inView={isInView}
              />
            ))}
          </div>
        </Container>
      </div>
    </>
  );
};

export default ColorBanner;
