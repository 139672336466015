import React from "react";
import { motion } from "framer-motion";

const FadeAnimation = ({
  children,
  staggerDuration = 0,
  delay = 0,
  inView = true,
  duration = 0.1,
  ...props
}) => {
  const fadeVariants = {
    hidden: { opacity: 0, y: 5 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration,
        ease: [0.6, -0.05, 0.01, 0.99],
        staggerChildren: staggerDuration,
        when: "beforeChildren",
        delay,
      },
    },
    exit: {
      opacity: 0,
      y: 5,
      transition: {
        duration: 0.4,
        ease: [0.6, -0.05, 0.01, 0.99],
      },
    },
  };

  return (
    <motion.div
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      exit="exit"
      variants={fadeVariants}
      {...props}
    >
      {children}
    </motion.div>
  );
};

export default FadeAnimation;
