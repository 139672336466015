import React from "react";
import Marca1 from "../../assets/img/marcas-1.png";
import Marca2 from "../../assets/img/marcas-2.png";
import Marca3 from "../../assets/img/marcas-3.png";
import Marca0 from "../../assets/img/marcas-0.png";
import Container from "../Container";
import Auto from "../../assets/img/auto.svg";
import AutoChico from "../../assets/img/auto-chico.svg";
import Moto from "../../assets/img/moto.svg";
const Marcas = () => {
  return (
    <>
      <div className="marcas">
        <img src={Auto} className="auto-grande" alt="" />
        <Container>
          <h1>
            Elegí la compañía aseguradora que mejor se adapte a tus necesidades
            para hacerlo 100% online
          </h1>
          <div className="marcas-flex">
            <div className="una-marca">
              <img src={Marca0} alt="" />
            </div>
            <div className="line"></div>
            <div className="una-marca">
              <img src={Marca1} alt="" />
            </div>
            <div className="line"></div>
            <div className="una-marca">
              <img src={Marca2} alt="" />
            </div>
            <div className="line"></div>
            <div className="una-marca">
              <img src={Marca3} alt="" />
            </div>
          </div>
        </Container>
      </div>
      <div className="decoracion">
        <img src={AutoChico} alt="" />
        <img src={Moto} alt="" />
      </div>
    </>
  );
};

export default Marcas;
