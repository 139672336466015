import React, { useState } from "react";
import Logo from "../assets/img/logo-rojo.svg";
import Button from "./Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HoverDropdown from "./HoverDropdown";
import Burger from "../assets/img/burger.svg";
import MobileMenu from "./MobileMenu";
import { AnimatePresence } from "framer-motion";
const Navigation = () => {
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <>
      <header>
        {window.innerWidth > 768 ? (
          <nav>
            <Link to={"/"}>
              <img src={Logo} alt="" />
            </Link>
            <div className="navi-links">
              <HoverDropdown
                text={"Préstamos"}
                isActive={pathname === "/prestamos"}
              />
              <Link
                to={"/seguros"}
                className={pathname === "/seguros" ? "active" : ""}
              >
                Seguros
              </Link>
              <Link
                to={"/asistencias"}
                className={pathname === "/asistencias" ? "active" : ""}
              >
                Asistencias
              </Link>
              <Link
                to={"/quienes-somos"}
                className={pathname === "/quienes-somos" ? "active" : ""}
              >
                Quiénes somos
              </Link>
            </div>
            <div className="button-group">
              <Button variant="outline-red-small" onClick={() => {}}>
                Portal Comercio
              </Button>
              <Button
                variant={"filled-red-small"}
                onClick={() => {
                  navigate("/adherir-comercio");
                }}
              >
                Adherí tu comercio
              </Button>
            </div>
          </nav>
        ) : (
          <nav>
            <img src={Burger} alt="" onClick={() => setIsMobileOpen(true)} />
            <img src={Logo} alt="" className="logo" />
          </nav>
        )}
      </header>
      <AnimatePresence>
        {isMobileOpen && (
          <MobileMenu
            isOpen={isMobileOpen}
            onClose={() => setIsMobileOpen(false)}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default Navigation;
