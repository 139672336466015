import React from "react";
import { AnimatePresence, motion } from "framer-motion";

const Modal = ({ isOpen, onClose, children }) => {
  const modalVariants = {
    hidden: {
      opacity: 0,
      scale: 0.5,
      rotate: -5,
      y: -10,
    },
    visible: {
      opacity: 1,
      scale: 1,
      rotate: 0,
      transition: {
        type: "spring",
        bounce: 0.55,
        duration: 0.7,
      },
    },
    exit: {
      opacity: 0,
      scale: 0.5,
      rotate: -5,
      y: -10,
      transition: { duration: 0.3, ease: "backIn" },
    },
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="modal-overlay" onClick={onClose}>
          <motion.div
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit={"exit"}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <button className="modal-close" onClick={onClose}>
              &times;
            </button>
            {children}
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default Modal;
