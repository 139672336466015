import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "./Modal";
import Button from "./Button";
import SimModal from "../assets/img/sim-modal.png";

const HoverDropdown = ({ text, isActive }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div
        className="hover-dropdown"
        onMouseOver={() => setIsOpen(true)}
        onMouseOut={() => setIsOpen(false)}
      >
        <h3 className={isActive && "active"}>{text}</h3>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={isActive && "active"}
        >
          <g id="chevron-down">
            <g id="Path (Stroke)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.8536 5.14645C14.6583 4.95118 14.3417 4.95118 14.1464 5.14645L8.5 10.7929L2.85355 5.14645C2.65829 4.95118 2.34171 4.95118 2.14645 5.14645C1.95118 5.34171 1.95118 5.65829 2.14645 5.85355L8.14645 11.8536C8.34171 12.0488 8.65829 12.0488 8.85355 11.8536L14.8536 5.85355C15.0488 5.65829 15.0488 5.34171 14.8536 5.14645Z"
                fill="#9BC3D3"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.8536 5.14645C14.6583 4.95118 14.3417 4.95118 14.1464 5.14645L8.5 10.7929L2.85355 5.14645C2.65829 4.95118 2.34171 4.95118 2.14645 5.14645C1.95118 5.34171 1.95118 5.65829 2.14645 5.85355L8.14645 11.8536C8.34171 12.0488 8.65829 12.0488 8.85355 11.8536L14.8536 5.85355C15.0488 5.65829 15.0488 5.34171 14.8536 5.14645Z"
                fill="black"
                fillOpacity="0.25"
              />
            </g>
          </g>
        </svg>

        {isOpen && (
          <div
            className={"dropdown-content"}
            onMouseOver={() => setIsOpen(true)}
          >
            <span
              onClick={() => {
                navigate("/prestamos");
              }}
            >
              Personales
            </span>
            <div className="hline"></div>
            <span onClick={handleModalOpen}>Prendarios</span>
          </div>
        )}
      </div>
      <Modal isOpen={isModalOpen} onClose={handleModalClose}>
        <div className="sim-modal-open">
          <div className="sim-modal-img">
            <img src={SimModal} alt="" />
          </div>
          <div className="sim-modal-text">
            <h5>
              Mirá todo lo que podés hacer con un Súper Préstamo Prendario
            </h5>
            <span>
              Te llevaremos al sitio de Santander para que puedas sacar tu
              préstamo
            </span>
            <p>
              Comprá o cambiá tu vehículo con las mejores opciones de
              financiación. Podés aprovechar los múltiples beneficios y
              préstamos que tenemos para ofrecerte. Alcanzar tus metas ¡nunca
              fue tan sencillo!
            </p>
            <Button
              variant={"filled-red"}
              onClick={() =>
                (window.location.href =
                  "https://www.santander.com.ar/personas/prendarios/prestamos-prendarios")
              }
            >
              Conseguí el tuyo
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default HoverDropdown;
