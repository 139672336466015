import React, { useEffect, useState } from "react";
import Button from "./Button";
import Modal from "./Modal";
import SimModal from "../assets/img/sim-modal.png";
import { AnimatePresence } from "framer-motion";
import { fadeInVariants } from "../animations/fadeInVariants";
import FadeAnimation from "./Animations/FadeAnimation";
import StaggeredParagraphs from "./Animations/StaggeredParagraphs";
const HeaderSliderPanel = ({ hsElement, activeSlide, handleModalOpen }) => {
  const getUrlFoto = () => {
    // First encode the URL to handle special characters and spaces
    const encodedUrl = encodeURI(hsElement.urlFoto);

    // Further encode parentheses and spaces as they can cause issues in CSS
    const cssEncodedUrl = encodedUrl
      .replace(/\(/g, "%28")
      .replace(/\)/g, "%29")
      .replace(/%20/g, "%20");

    // Return just the url string, not the full CSS property
    return `url("${cssEncodedUrl}")`;
  };

  return (
    <>
      <FadeAnimation
        variants={fadeInVariants}
        className="col-md-6 col-12"
        key={activeSlide}
      >
        <div className="bg-img" style={{ backgroundImage: getUrlFoto() }}></div>
      </FadeAnimation>
      <div className="col-md-6 col-12 hb-bg">
        <FadeAnimation staggerDuration={0.1} className="hb-text">
          <StaggeredParagraphs>
            <span
              className="icono-texto"
              dangerouslySetInnerHTML={{
                __html: hsElement.subtitulo,
              }}
            ></span>
            <h2 style={{ color: hsElement.colorSeccion }}>
              {hsElement.titulo}
            </h2>
            <p
              dangerouslySetInnerHTML={{
                __html: hsElement.copy,
              }}
            ></p>
            <div
              className="hb-button-container"
              style={{
                marginLeft: `${hsElement.isButtonLeft ? "0" : "auto"}`,
              }}
            >
              <Button
                variant={"filled-red"}
                onClick={() => {
                  if (hsElement.urlDestinoBoton) {
                    window.location.href = hsElement.urlDestinoBoton;
                  } else {
                    handleModalOpen();
                  }
                }}
              >
                {hsElement.textoBoton}
              </Button>
            </div>
          </StaggeredParagraphs>
        </FadeAnimation>
      </div>
    </>
  );
};

const HeaderSliderDots = ({ hsElements, setActiveSlide, activeSlide }) => {
  return (
    <div className="slider-circles">
      {hsElements.map((el, i) => (
        <div
          className={`scircle ${i === activeSlide ? "active" : ""}`}
          onClick={() => setActiveSlide(i)}
        ></div>
      ))}
    </div>
  );
};

const HeaderSlider = ({ hsElements }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const handleSlideChange = () => {
      if (activeSlide === hsElements.length - 1) {
        setActiveSlide(0);
      } else {
        setActiveSlide(activeSlide + 1);
      }
    };

    const intervalId = setInterval(handleSlideChange, 4000);

    return () => {
      clearInterval(intervalId);
    };
  }, [activeSlide, hsElements.length]);

  return (
    <>
      <div className="hb row g-0">
        <AnimatePresence mode="wait">
          {hsElements.map((el, i) => {
            if (i !== activeSlide) return false;
            return (
              <HeaderSliderPanel
                hsElement={hsElements[activeSlide]}
                activeSlide={activeSlide}
                handleModal={handleModalOpen}
                key={i}
              />
            );
          })}
        </AnimatePresence>
        <HeaderSliderDots
          hsElements={hsElements}
          activeSlide={activeSlide}
          setActiveSlide={setActiveSlide}
        />
      </div>
      <Modal isOpen={isModalOpen} onClose={handleModalClose}>
        <div className="sim-modal-open">
          <div className="sim-modal-img">
            <img src={SimModal} alt="" />
          </div>
          <div className="sim-modal-text">
            <h5>
              Mirá todo lo que podés hacer con un Súper Préstamo Prendario
            </h5>
            <span>
              Te llevaremos al sitio de Santander para que puedas sacar tu
              préstamo
            </span>
            <p>
              Comprá o cambiá tu vehículo con las mejores opciones de
              financiación. Podés aprovechar los múltiples beneficios y
              préstamos que tenemos para ofrecerte. Alcanzar tus metas ¡nunca
              fue tan sencillo!
            </p>
            <Button
              onClick={() =>
                (window.location.href =
                  "https://www.santander.com.ar/personas/prendarios/prestamos-prendarios")
              }
              variant={"filled-red"}
            >
              Conseguí el tuyo
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default HeaderSlider;
