import React from "react";
import Button from "../Button";

const SeParte = () => {
  return (
    <div className="vertical-spacing se-parte">
      <h4>Sé parte de nuestro equipo</h4>
      <Button variant={"filled-red-small"} onClick={() => window.location.href = "https://santander.wd3.myworkdayjobs.com/es/SantanderCareers?locationCountry=e42ad5eac46d4cc9b367ceaef42577c5"}>Trabajá con nostros</Button>
    </div>
  );
};

export default SeParte;
