import React from "react";
import HeaderBanner from "../components/HeaderBanner";
import Button from "../components/Button";
import Hb from "../assets/img/hb5.jpg";
import HbM from "../assets/img/hbm5.jpg";
import Financiar from "../components/QuienesSomos/Financiar";
import Desafios from "../components/QuienesSomos/Desafios";
import Caracteristicas from "../components/QuienesSomos/Caracteristicas";
import SeParte from "../components/QuienesSomos/SeParte";
const QuienesSomos = () => {
  return (
    <>
      <HeaderBanner
        imgPath={window.innerWidth > 768 ? Hb : HbM}
        italicText={`quienes somos`}
        title={"Nacimos de una entidad, hoy tenemos identidad"}
        description={
          "Somos parte de un grupo financiero, pero cuando nos quisimos acordar <b>ya teníamos vuelo e identidad propia</b>"
        }
        titleColor={"#EC0000"}
        button={<Button onClick={() => window.location.href = "https://santander.wd3.myworkdayjobs.com/es/SantanderCareers?locationCountry=e42ad5eac46d4cc9b367ceaef42577c5"} variant={"filled-red"}>¡Sumate al equipo!</Button>}
        isButtonLeft={false}
      />
      <Financiar />
      <Desafios />
      <Caracteristicas />
      <SeParte />
    </>
  );
};

export default QuienesSomos;
