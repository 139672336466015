import React from "react";
import FadeAnimation from "./Animations/FadeAnimation";
import StaggeredParagraphs from "./Animations/StaggeredParagraphs";
import { fadeInVariantsLong } from "../animations/fadeInVariants";
const HeaderBanner = ({
  imgPath,
  italicText,
  title,
  description,
  button,
  titleColor,
  isButtonLeft,
}) => {
  return (
    <div className="hb row g-0">
      <FadeAnimation className="col-md-6 col-12" variants={fadeInVariantsLong}>
        <div
          className="bg-img"
          style={{ backgroundImage: `url(${imgPath})` }}
        ></div>
      </FadeAnimation>
      <div className="col-md-6 col-12 hb-bg ">
        <FadeAnimation staggerDuration={0.1} className="hb-text">
          <StaggeredParagraphs>
            <span
              className="icono-texto"
              dangerouslySetInnerHTML={{ __html: italicText }}
            ></span>
            <h2 style={{ color: titleColor }}>{title}</h2>
            <p dangerouslySetInnerHTML={{ __html: description }}></p>
            <div
              className="hb-button-container"
              style={{
                marginLeft: `${isButtonLeft ? "0" : "auto"}`,
              }}
            >
              {button}
            </div>
          </StaggeredParagraphs>
        </FadeAnimation>
      </div>
    </div>
  );
};

export default HeaderBanner;
