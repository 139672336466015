import React from "react";

const CarFront = ({ fill = "" }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="car-front">
        <g id="Union">
          <path
            d="M6 14.3276C6 15.156 5.32843 15.8276 4.5 15.8276C3.67157 15.8276 3 15.156 3 14.3276C3 13.4991 3.67157 12.8276 4.5 12.8276C5.32843 12.8276 6 13.4991 6 14.3276Z"
            fill={`${fill || "#EC0000"}`}
          />
          <path
            d="M21 14.3276C21 15.156 20.3284 15.8276 19.5 15.8276C18.6716 15.8276 18 15.156 18 14.3276C18 13.4991 18.6716 12.8276 19.5 12.8276C20.3284 12.8276 21 13.4991 21 14.3276Z"
            fill={`${fill || "#EC0000"}`}
          />
          <path
            d="M9 12.8276C8.17157 12.8276 7.5 13.4991 7.5 14.3276C7.5 15.156 8.17157 15.8276 9 15.8276H15C15.8284 15.8276 16.5 15.156 16.5 14.3276C16.5 13.4991 15.8284 12.8276 15 12.8276H9Z"
            fill={`${fill || "#EC0000"}`}
          />
          <path
            d="M7.29271 7.24217L5.85854 10.1105C5.59439 10.6388 6.01643 11.2555 6.60519 11.2081C7.96811 11.0983 10.1298 10.9526 12 10.9526C13.8702 10.9526 16.0319 11.0983 17.3948 11.2081C17.9836 11.2555 18.4056 10.6388 18.1415 10.1105L16.7073 7.24217C16.5803 6.98808 16.3206 6.82758 16.0365 6.82758H7.96353C7.67945 6.82758 7.41975 6.98808 7.29271 7.24217Z"
            fill={`${fill || "#EC0000"}`}
          />
          <path
            d="M3.78139 6.10038C4.37231 4.72157 5.72808 3.82758 7.22819 3.82758H16.7718C18.2719 3.82758 19.6277 4.72156 20.2186 6.10038L21.4065 8.87203C21.5192 9.13521 21.7217 9.34988 21.9778 9.47793C22.7259 9.852 23.2594 10.5505 23.4235 11.3707L23.9272 13.8892C23.9756 14.1313 24 14.3777 24 14.6246V15.2441C24 16.4651 23.4145 17.5583 22.5 18.2404V21.0776C22.5 21.4918 22.1642 21.8276 21.75 21.8276H18.75C18.3358 21.8276 18 21.4918 18 21.0776V19.0706C16.0626 19.1426 13.8831 19.2026 12 19.2026C10.1169 19.2026 7.93745 19.1426 6 19.0706V21.0776C6 21.4918 5.66421 21.8276 5.25 21.8276H2.25C1.83579 21.8276 1.5 21.4918 1.5 21.0776V18.2404C0.585496 17.5583 0 16.4651 0 15.2441V14.6246C0 14.3777 0.0243926 14.1313 0.0728225 13.8892L0.576512 11.3707C0.740555 10.5505 1.27409 9.852 2.02224 9.47793C2.27833 9.34988 2.48075 9.13521 2.59354 8.87203L3.78139 6.10038ZM7.22819 5.32758C6.32813 5.32758 5.51466 5.86397 5.16011 6.69126L3.97226 9.46291C3.71972 10.0522 3.26648 10.5329 2.69306 10.8196C2.35893 10.9866 2.12065 11.2986 2.04738 11.6649L1.54369 14.1834C1.51464 14.3287 1.5 14.4765 1.5 14.6246V15.2441C1.5 16.4388 2.42888 17.4166 3.61183 17.4703C5.94426 17.5764 9.29081 17.7026 12 17.7026C14.7092 17.7026 18.0557 17.5764 20.3882 17.4703C21.5711 17.4166 22.5 16.4388 22.5 15.2441V14.6246C22.5 14.4765 22.4854 14.3287 22.4563 14.1834L21.9526 11.6649C21.8794 11.2986 21.6411 10.9866 21.3069 10.8196C20.7335 10.5329 20.2803 10.0522 20.0277 9.46291L18.8399 6.69126C18.4853 5.86397 17.6719 5.32758 16.7718 5.32758H7.22819Z"
            fill={`${fill || "#EC0000"}`}
          />
        </g>
      </g>
    </svg>
  );
};

export default CarFront;
