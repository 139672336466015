import React from "react";
import Concept from "../../assets/img/concept.png";
import Container from "../Container";
const Financiar = () => {
  return (
    <div className="financiar vertical-spacing">
      <Container>
        <div className="financiar-flex">
          <img src={Concept} alt="" />
          <div className="financiar-text">
            <h3>Financiar de forma sostenible y responsable</h3>
            <p>
              Salimos a la cancha con el propósito de ayudar a las personas y a
              las empresas a desarrollarse.
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Financiar;
