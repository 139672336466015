import React from "react";
import Logo from "../assets/img/logo-rojo.svg";
import { ClipLoader } from "react-spinners";
import FadeAnimation from "../components/Animations/FadeAnimation";
const Loading = ({ isVisible }) => {
  return (
    isVisible && (
      <FadeAnimation duration={2} className="loading-screen">
        <img src={Logo} alt="" />
        <ClipLoader
          color="#ec0000"
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </FadeAnimation>
    )
  );
};

export default Loading;
