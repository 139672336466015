import React from "react";

const Shield = ({ fill = "" }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00691 3.21383C6.37792 3.65541 4.72821 4.17744 3.75183 4.49605C3.4832 4.5837 3.29456 4.81633 3.25914 5.08215C2.42833 11.3169 4.34795 15.8676 6.63846 18.864C7.78845 20.3684 9.03273 21.4812 10.07 22.2127C10.5891 22.5787 11.048 22.8434 11.4086 23.0131C11.5891 23.0981 11.7367 23.1554 11.8489 23.1901C11.9412 23.2187 11.9887 23.2255 12 23.2271C12.0113 23.2255 12.0588 23.2187 12.1511 23.1901C12.2633 23.1554 12.4109 23.0981 12.5914 23.0131C12.952 22.8434 13.4109 22.5787 13.93 22.2127C14.9673 21.4812 16.2115 20.3684 17.3615 18.864C19.652 15.8676 21.5717 11.3169 20.7409 5.08215C20.7054 4.81633 20.5168 4.5837 20.2482 4.49605C19.2718 4.17744 17.6221 3.65541 15.9931 3.21383C14.3275 2.76235 12.7963 2.42758 12 2.42758C11.2037 2.42758 9.67245 2.76235 8.00691 3.21383ZM7.60748 1.66705C9.23608 1.22559 10.9658 0.827576 12 0.827576C13.0342 0.827576 14.7639 1.22559 16.3925 1.66705C18.0577 2.11843 19.7353 2.64949 20.7221 2.9715C21.5456 3.24023 22.1685 3.96796 22.2882 4.86572C23.1831 11.5817 21.1079 16.5579 18.591 19.8506C17.3373 21.4907 15.9746 22.7131 14.8154 23.5306C14.2361 23.9391 13.6993 24.2524 13.2434 24.467C12.8222 24.6653 12.3716 24.8276 12 24.8276C11.6284 24.8276 11.1778 24.6653 10.7566 24.467C10.3007 24.2524 9.76386 23.9391 9.18464 23.5306C8.02536 22.7131 6.66274 21.4907 5.409 19.8506C2.89206 16.5579 0.816912 11.5817 1.71185 4.86572C1.83148 3.96796 2.45438 3.24023 3.27792 2.9715C4.26474 2.64949 5.94234 2.11843 7.60748 1.66705Z"
        fill={`${fill || "#63BA68"}`}
      />
      <path
        d="M14.25 10.5776C14.25 11.5572 13.6239 12.3907 12.75 12.6995L13.3274 15.6852C13.4169 16.1479 13.0624 16.5776 12.5911 16.5776H11.4089C10.9376 16.5776 10.5831 16.1479 10.6726 15.6852L11.25 12.6995C10.3761 12.3907 9.75 11.5572 9.75 10.5776C9.75 9.33493 10.7574 8.32758 12 8.32758C13.2426 8.32758 14.25 9.33493 14.25 10.5776Z"
        fill={`${fill || "#63BA68"}`}
      />
    </svg>
  );
};

export default Shield;
