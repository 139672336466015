import React from "react";

const Button = ({ variant, children, onClick, type = "button" }) => {
  return (
    <button className={`btn ${variant}`} onClick={onClick} type={type}>
      {children}
    </button>
  );
};

export default Button;
