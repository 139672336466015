import { motion } from "framer-motion";
import React from "react";
const StaggeredParagraphs = ({ children }) => {
  const paragraphVariants = {
    hidden: {
      opacity: 0,
      x: -50,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        damping: 100,
        stiffness: 550,
      },
    },
  };

  return React.Children.map(children, (child, index) => (
    <motion.div key={index} variants={paragraphVariants}>
      {child}
    </motion.div>
  ));
};

export default StaggeredParagraphs;
