import React, { useEffect, useState } from "react";
import HeaderBanner from "../components/HeaderBanner";
import StepByStep from "../components/StepByStep";
import ColorBanner from "../components/ColorBanner";
import Form from "../components/AdherirComercio/Form";
import AccordionGroup from "../components/Accordion/AccordionGroup";
import Hb from "../assets/img/hb-1.jpg";
import HbM from "../assets/img/hbm-1.jpg";
import Button from "../components/Button";
import Emoji from "../assets/img/emoji-smile.svg";
import Container from "../components/Container";

import Cbh1 from "../assets/img/cbh-1.svg";
import Cbh2 from "../assets/img/cbh-2.svg";
import Cbh3 from "../assets/img/cbh-3.svg";
import Cbh4 from "../assets/img/cbh-4.svg";

const AdherirComercio = () => {
  const [accordionElements, setAccordionElements] = useState([]);
  const steps = [
    { title: "Completá", subtitle: "Ingresá los datos de tu cliente." },
    {
      title: "Evaluá",
      subtitle: "Realizá la evaluación crediticia (100% online).",
    },
    {
      title: "Vendé",
      subtitle:
        "Tu cliente valida su identidad y acepta el crédito ¡ya podés realizar la venta!",
    },
  ];

  const colorBannerElements = [
    { icon: Cbh1, text: "<b>100% online</b><br/> no requiere integración" },
    { icon: Cbh2, text: "Ofrecés <b>cuotas fijas</b>" },
    { icon: Cbh3, text: "<b>Mínimos requisitos</b>" },
    { icon: Cbh4, text: "<b>No necesita presentar</b> recibo de sueldo" },
  ];

  useEffect(() => {
    const getAccordionElements = async () => {
      const res = await fetch(
        "https://santander-consumer-v2-e0c89957455a.herokuapp.com/api/faqs/list/Prestamos%20Personales"
      );

      const json = await res.json();
      return json;
    };

    getAccordionElements().then((json) => setAccordionElements(json));
  }, []);

  const formFields = [
    { name: "razonSocial", placeholder: "Razon Social" },
    { name: "nombreFantasia", placeholder: "Nombre fantasia" },
    { name: "cuit", placeholder: "CUIT" },
    { name: "nombreContacto", placeholder: "Nombre contacto" },
    { name: "telefono", placeholder: "Telefono" },
    { name: "email", placeholder: "Email" },
    { name: "rubro", placeholder: "Rubro" },
  ];

  return (
    <>
      <HeaderBanner
        imgPath={window.innerWidth > 768 ? Hb : HbM}
        italicText={"Alianza Consumer"}
        title={"Potenciá tu comercio"}
        description={
          "¡Trabajá con nosotros y <b>ofrecé financiación rápida, ágil y fácil!</b>"
        }
        titleColor={"#9E3667"}
        button={
          <a href="#formulario">
            <Button variant={"filled-red"}>Quiero adherir mi comercio</Button>
          </a>
        }
      />
      <Container>
        <StepByStep
          color={"purple"}
          icon={Emoji}
          subtitle={"Te contamos cómo funciona"}
          title={"Vendé más ofreciendo cuotas fijas"}
          steps={steps}
        />
      </Container>

      <ColorBanner
        color={"purple"}
        elements={colorBannerElements}
        title={"Ofrecemos el mejor producto para vos y tus clientes"}
      />
      <Form
        fields={formFields}
        title={"Dejanos tus datos y nos contactaremos a la brevedad"}
        subtitle={
          "Te acompañaremos en la gestión diaria, ofreciéndote beneficios exclusivos para que puedas vender más. ¡Y no sólo eso! Además tendrás un centro de atención exclusiva para vos"
        }
      />
      <AccordionGroup
        elements={accordionElements}
        title={"Tenemos las respuestas a tus consultas"}
      />
    </>
  );
};

export default AdherirComercio;
