import React, { useState } from "react";
import Container from "../Container";
import { useNavigate } from "react-router-dom";

const UnPyS = ({
  icon,
  title,
  description,
  buttonText,
  colorScheme,
  link,
  hoverIcon,
}) => {
  const navigate = useNavigate();
  const [isHovering, setIsHovering] = useState(false);
  const handleClick = () => {
    if (typeof link === "string") {
      navigate(link);
    } else {
      link();
    }
  };

  return (
    <div
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      className={`pys-box ${colorScheme}`}
      onClick={handleClick}
    >
      <div className="pys-icon">
        {isHovering ? <img src={hoverIcon} alt={title} /> : icon}
      </div>
      <h6>{title}</h6>
      <p>{description}</p>
      <button>{buttonText}</button>
    </div>
  );
};

const ProductosYServicios = ({ elements }) => {
  return (
    <div className="vertical-spacing pys">
      <Container>
        <h4>Conocé nuestros productos y servicios</h4>
        <div className="pys-flex">
          {elements.map((pys, i) => (
            <>
              <UnPyS
                buttonText={pys.buttonText}
                colorScheme={pys.colorScheme}
                description={pys.description}
                icon={pys.icon}
                title={pys.title}
                link={pys.link}
                hoverIcon={pys.hoverIcon}
                key={i}
              />
              {i !== elements.length - 1 && <div className="vline"></div>}
            </>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default ProductosYServicios;
