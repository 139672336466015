import React from "react";
import Container from "../Container";
import Pre1 from "../../assets/img/pre-1.png";
import Pre2 from "../../assets/img/pre-2.png";
import Pre3 from "../../assets/img/pre-3.png";
import PreMobile1 from "../../assets/img/pre-m-1.png";
import PreMobile3 from "../../assets/img/pre-m-3.png";
import FadeAndJump from "../Animations/FadeAndJump";
import { useElementInView } from "../../hooks/useElementInView";
const Prestamos = () => {
  const { ref, isInView } = useElementInView({
    once: true,
    amount: 0.5,
  });
  return (
    <div className="prestamos vertical-spacing">
      <h4>Préstamos al consumo</h4>
      <h5>Date el gusto y comprá en cuotas fijas en comercios</h5>
      <Container>
        <div className="pcard-flex" ref={ref}>
          <FadeAndJump inView={isInView} className="prestamo-card">
            <div className="p-head">
              <img src={window.innerWidth > 768 ? Pre1 : PreMobile1} alt="" />
            </div>
            <div className="p-body">
              <h6>Locales adheridos</h6>
              <p>
                Estamos presentes en más de 1300 comercios en todo el país.
                Identificá nuestro logo en los locales y comprá en cuotas fijas.
              </p>
            </div>
          </FadeAndJump>
          <FadeAndJump inView={isInView} delay={0.2} className="prestamo-card">
            <div className="p-head">
              <img src={window.innerWidth > 768 ? Pre2 : PreMobile3} alt="" />
            </div>
            <div className="p-body">
              <h6>¿Qué puedo comprar?</h6>
              <p>
                Podés acceder a la compra de electro, indumentaria, tecnología,
                motos, bicis, corralones, muebles y muchos más. También, podés
                llevarte efectivo en el acto.
              </p>
            </div>
          </FadeAndJump>
          <FadeAndJump delay={0.4} inView={isInView} className="prestamo-card">
            <div className="p-head">
              <img src={window.innerWidth > 768 ? Pre3 : PreMobile3} alt="" />
            </div>
            <div className="p-body">
              <h6>¿Cuáles son los requisitos?</h6>
              <p>
                Comprá presentando sólo tu DNI, sin necesidad de tener una
                tarjeta de crédito.
              </p>
            </div>
          </FadeAndJump>
        </div>
      </Container>
    </div>
  );
};

export default Prestamos;
