import React from "react";

import AdherirImg from "../../assets/img/adherir.png";
import Container from "../Container";
import Button from "../Button";
import { Link } from "react-router-dom";

const Adherir = () => {
  return (
    <div className="adherir vertical-spacing">
      <Container>
        <div className="adherir-flex">
          <div className="">
            <img src={AdherirImg} alt="" draggable={false} />
          </div>
          <div className="adherir-text">
            <h5>¿Todavía no adheriste tu comercio a Santander Consumer?</h5>
            <p>
              Trabajá con nosotros y ofrecele a tus clientes una financiación
              rápida y fácil.
            </p>
            <Link to="/adherir-comercio">
              <Button variant={"filled-red-small"}>Enterate más</Button>
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Adherir;
