import React from "react";
import Translado from "../../assets/img/translado.svg";
import Container from "../Container";
import Ajuste from "../../assets/img/ajuste.svg";
import Ambulancia from "../../assets/img/ambulancia.svg";
import Cog from "../../assets/img/cog.svg";
import Papel from "../../assets/img/papel.svg";
import Candado from "../../assets/img/candado.svg";
import SOS from "../../assets/img/SOS.svg";
import Telefono from "../../assets/img/telefono.svg";
import Herramientas from "../../assets/img/herramientas.svg";
import Hogar from "../../assets/img/hogar.svg";
import Diente from "../../assets/img/diente.svg";
import Regalo from "../../assets/img/regalo.svg";
const UnBeneficio = ({ icon, text }) => {
  return (
    <div className="un-beneficio">
      <img src={icon} alt="" />
      <p>{text}</p>
    </div>
  );
};

const Beneficios = () => {
  const beneficiosList = [
    {
      icon: Translado,
      text: "Traslado",
    },
    {
      icon: Ajuste,
      text: "Ajuste de frenos, engrasado y limpieza general",
    },
    {
      icon: Ambulancia,
      text: "Ambulancia",
    },
    {
      icon: Cog,
      text: "Mantenimiento",
    },
    {
      icon: Papel,
      text: "Reposición de documentos",
    },
    {
      icon: Candado,
      text: "Cambio de cerradura",
    },
    {
      icon: SOS,
      text: "Técnico para asistencia telefónica-remota",
    },
    {
      icon: Telefono,
      text: "Consultas médicas con videollamada",
    },
    {
      icon: Herramientas,
      text: "Plomería, gasista, vidriería, cerrajería y electricista",
    },
    {
      icon: Hogar,
      text: "Instalaciones en el hogar",
    },
    {
      icon: Diente,
      text: "Urgencia odontológica",
    },
    {
      icon: Regalo,
      text: "Descuentos en farmacias y ortopedias",
    },
  ];

  return (
    <div className="vertical-spacing beneficios">
      <h1>Mirá los beneficios con los que podés contar</h1>
      <Container>
        <div className="beneficios-grid">
          {beneficiosList.map((ben, i) => (
            <UnBeneficio icon={ben.icon} text={ben.text} key={i} />
          ))}
        </div>
      </Container>
    </div>
  );
};

export default Beneficios;
