import React from "react";
import Container from "../Container";
import LeftArrow from "../../assets/img/left-arrow.svg";
import Alarm from "../../assets/img/alarm.svg";
import Heart from "../../assets/img/heart.svg";
import Vespa from "../../assets/img/vespa.svg";
import Scooter from "../../assets/img/scooter.svg";
import Shield from "../../assets/img/shield.svg";
const FeatureCard = ({ title, subtitle, icon, description }) => {
  return (
    <div className="feature-card">
      <div className="img-circle">
        <img src={icon} alt="" />
      </div>
      <div className="feature-text">
        <h2>{title}</h2>
        <h6>{subtitle}</h6>
        <p>{description}</p>
      </div>
      <a className="icono-texto features">
        <span>Contratá acá</span>
        <img src={LeftArrow} alt="" />
      </a>
    </div>
  );
};

const Features = () => {
  const featureList = [
    {
      title: "Multiasistencia",
      subtitle: "Asistencia para cada necesidad",
      description:
        "Salud, vía pública y tecnología. Además, contás con asistencias de urgencia en plomería, gasista y mucho más.",
      icon: Alarm,
    },
    {
      title: "Salud",
      subtitle: "Asistencia para tu cuidado",
      description:
        "Contás con servicio de ambulancia por accidente o robo, videollamadas médicas, segunda opinión médica, urgencias odontológicas, farmacias y ortopedias.",
      icon: Heart,
    },
    {
      title: "Moto",
      subtitle: "Asistencia para motociclistas",
      description:
        "En vía pública, reposición de documentación y servicios de cerrajería. Además, contás con servicio de ambulancia en caso de accidente, lesiones por robo y mucho más.",
      icon: Vespa,
    },
    {
      title: "Movilidad Urbana",
      subtitle: "Asistencia para bicicletas o monopatín",
      description:
        "Traslado de la bicicleta o el monopatín en caso de pinchadura o rotura de neumático, pedales o cadena. Traslado para el conductor por robo o rotura. Ambulancia en caso de accidente o robo.",
      icon: Scooter,
    },
  ];

  return (
    <div className="features vertical-spacing">
      <h3>Descubrí las asistencias que tenemos para ofrecerte</h3>
      <Container>
        <div className="feature-card-flex">
          {featureList.map((feat, i) => (
            <>
              <FeatureCard
                key={i}
                icon={feat.icon}
                description={feat.description}
                subtitle={feat.subtitle}
                title={feat.title}
                index={i}
              />
              {i !== featureList.length - 1 && <div className="vline"></div>}
            </>
          ))}
        </div>
      </Container>
      <img src={Shield} alt="" className="shield" />
    </div>
  );
};

export default Features;
