import React from "react";
import MujerSonriendo from "../../assets/img/desafios.jpg";
import FadeAnimation from "../Animations/FadeAnimation";
import StaggeredParagraphs from "../Animations/StaggeredParagraphs";
import { useElementInView } from "../../hooks/useElementInView";

const Desafios = () => {
  const { ref, isInView } = useElementInView({ once: true });
  return (
    <div className="desafios" ref={ref}>
      <FadeAnimation
        inView={isInView}
        staggerDuration={0.1}
        className="desafios-text"
      >
        <StaggeredParagraphs>
          <h4>
            Desde el principio hasta hoy nos acompañan los desafíos en nuestro
            día a día.
          </h4>
          <p>
            Fomentamos el crecimiento de las personas que hacen de Santander
            Consumer un mejor lugar para trabajar. Diseñamos una propuesta de
            valor integrando servicios, beneficios, instancias de desarrollo,
            reconocimiento, aprendizaje e innovación, para atraer y retener el
            capital humano que valoramos.
          </p>
        </StaggeredParagraphs>
      </FadeAnimation>
      <div className="desafios-imagen">
        <div className="desafios-imagen-content">
          <img src={MujerSonriendo} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Desafios;
