import React from "react";

const Step = ({ number, title, subtitle }) => {
  return (
    <div className="step">
      <div className="number">
        <span>{number + 1}</span>
      </div>
      <div className="step-text">
        <h5>{title}</h5>
        <p>{subtitle}</p>
      </div>
    </div>
  );
};

const StepByStep = ({ icon, title, subtitle, color, steps }) => {
  return (
    <div className={`step-by-step ${color}`}>
      <div className="sbs-title">
        <img src={icon} alt="" />
        <h3 dangerouslySetInnerHTML={{ __html: title }}></h3>
        <p>{subtitle}</p>
      </div>
      <div className="stepper">
        {steps.map((step, i) => (
          <Step
            number={i}
            title={step.title}
            subtitle={step.subtitle}
            key={i}
          />
        ))}
      </div>
    </div>
  );
};

export default StepByStep;
