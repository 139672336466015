import React from "react";
import HeaderBanner from "../components/HeaderBanner";
import Button from "../components/Button";
import Hb from "../assets/img/hb4.jpg";
import HbM from "../assets/img/hbm4.jpg";
import SOS from "../assets/img/sos.png";
import Features from "../components/Asistencias/Features";
import ColorBanner from "../components/ColorBanner";
import Cb1 from "../assets/img/cbf-1.svg";
import Cb2 from "../assets/img/cbf-2.svg";
import Cb3 from "../assets/img/cbf-3.svg";
import Beneficios from "../components/Asistencias/Beneficios";
import TyC from "../components/Asistencias/TyC";
import { useHeaderBanner } from "../hooks/useHeaderBanner";
const Asistencias = () => {
  const headerBannerData = useHeaderBanner("Asistencias");
  const colorBannerElements = [
    {
      icon: Cb1,
      text: "Contratá <b>100% online</b>",
    },
    {
      icon: Cb2,
      text: "Contás con diversos <b>servicios las 24 horas</b>",
    },
    {
      icon: Cb3,
      text: "Vas a tener <b>¡muchísimos beneficios!</b>",
    },
  ];

  return (
    <>
      <HeaderBanner
        imgPath={headerBannerData.urlFoto}
        title={headerBannerData.titulo}
        description={headerBannerData.copy}
        button={
          <Button variant={"filled-red"}>{headerBannerData.textoBoton}</Button>
        }
        italicText={`<span>${headerBannerData.subtitulo}</span><img src='${headerBannerData.urlLogoSubtitulo}' class='hb-icon'/>`}
        titleColor={"#E9BA2E"}
        isButtonLeft={false}
      />
      <Features />
      <ColorBanner
        title={"¡Ganá en tranquilidad!"}
        subtitle={
          "Te contamos porqué es tan importante contratar tu asistencia."
        }
        color={"yellow"}
        elements={colorBannerElements}
      />
      <Beneficios />
      <TyC />
    </>
  );
};

export default Asistencias;
