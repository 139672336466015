import React, { useEffect, useState } from "react";
import HeaderBanner from "../components/HeaderBanner";
import Container from "../components/Container";
import StepByStep from "../components/StepByStep";
import ColorBanner from "../components/ColorBanner";
import Button from "../components/Button";
import Emoji from "../assets/img/emoji-wink.svg";
import Cb1 from "../assets/img/cb1.svg";
import Cb2 from "../assets/img/cb2.svg";
import Cb3 from "../assets/img/cb3.svg";
import MetodosDePago from "../components/Prestamos/MetodosDePago";
import AccordionGroup from "../components/Accordion/AccordionGroup";
import TyC from "../components/Prestamos/TyC";
import Modal from "../components/Modal";
import Fono from "../assets/img/fono.png";
import { useHeaderBanner } from "../hooks/useHeaderBanner";
const Prestamos = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [accordionElements, setAccordionElements] = useState([]);
  const headerBannerData = useHeaderBanner("Prestamos Personales");
  useEffect(() => {
    const getAccordionElements = async () => {
      const res = await fetch(
        "https://santander-consumer-v2-e0c89957455a.herokuapp.com/api/faqs/list/Prestamos%20Personales"
      );

      const json = await res.json();
      return json;
    };

    getAccordionElements().then((json) => setAccordionElements(json));
  }, []);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const steps = [
    {
      title: "Completá",
      subtitle:
        "Lo pedís con mínimos requisitos. Completá tus datos y sólo vas a necesitar tu DNI y una selfie.",
    },
    {
      title: "Pedí",
      subtitle:
        "Elegí el monto que querés y la cantidad de cuotas para devolverlo.",
    },
    {
      title: "Recibí",
      subtitle: "¡Listo! Acreditación en hasta 48h.",
    },
  ];

  const colorBannerElements = [
    {
      icon: Cb1,
      text: "<p class='cb-small-title'>SIMPLE</p>Lo sacás en <b>pocos pasos desde tu celular</b>",
    },
    {
      icon: Cb2,
      text: "<p class='cb-small-title'>RÁPIDO</p>En menos de <b>48 horas</b>",
    },
    {
      icon: Cb3,
      text: "<p class='cb-small-title'>FLEXIBLE</p>Vos elegís <b>cuánto y cómo pagarlo</b>",
    },
  ];

  return (
    <>
      {headerBannerData && (
        <HeaderBanner
          imgPath={headerBannerData.urlFoto}
          italicText={`<span>${headerBannerData.subtitulo}</span><img src='${headerBannerData.urlLogoSubtitulo}' class='hb-icon'/>`}
          title={headerBannerData.titulo}
          description={headerBannerData.copy}
          titleColor={"#1BB3BC"}
          button={
            <Button variant={"filled-red"} onClick={handleModalOpen}>
              {headerBannerData.textoBoton}
            </Button>
          }
          isButtonLeft={false}
        />
      )}
      <Container>
        <StepByStep
          color={"blue"}
          icon={Emoji}
          subtitle={""}
          title={"Tené tu préstamo online en pocos pasos"}
          steps={steps}
        />
      </Container>
      <ColorBanner
        title={"¿Por qué elegir Santander Consumer?"}
        subtitle={""}
        color={"blue"}
        elements={colorBannerElements}
      />
      <MetodosDePago />
      <AccordionGroup
        elements={accordionElements}
        title={"Tenemos las respuestas a tus consultas"}
      />
      <TyC />
      <Modal isOpen={isModalOpen} onClose={handleModalClose}>
        <div className="pop-open">
          <img src={Fono} alt="" className="fono" />
          <div>
            <h5>Ingresá tu número de teléfono</h5>
            <p>
              Te llevaremos al sitio de Santander para que puedas sacar tu
              préstamo
            </p>
          </div>
          <div>
            <div className="m-input-group">
              <span>+</span>
              <input
                type="text"
                name=""
                id=""
                placeholder="Cód. área"
                style={{
                  width: `100%`,
                }}
              />
              <span>11</span>
              <input
                type="text"
                name=""
                id=""
                placeholder="Número"
                style={{
                  width: `100%`,
                }}
              />
            </div>
            <p>
              Vas a recibir por mensaje de texto (SMS) un link para continuar
              con el proceso. Recordá tener a mano tu DNI y el número de CBU/CVU
              o Alias de tu cuenta.
            </p>
          </div>
          <div className="m-button-group">
            <Button variant={"filled-red-small"} onClick={handleModalClose}>
              Volver
            </Button>
            <Button variant={"outline-red-small"}>Enviar</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Prestamos;
