import React from "react";

const Container = ({ children, ...props }) => {
  return (
    <div className={`general-container`} {...props}>
      {children}
    </div>
  );
};

export default Container;
