import React, { useContext, useEffect, useState } from "react";
import ProductosYServicios from "../components/Home/ProductosYServicios";
import Adherir from "../components/Home/Adherir";
import Prestamos from "../components/Home/Prestamos";
import Reviews from "../components/Home/Reviews";
import HeaderSlider from "../components/HeaderSlider";
import Coin from "../components/Icons/Coin";
import CarFront from "../components/Icons/CarFront";
import Shield from "../components/Icons/Shield";
import Telephone from "../components/Icons/Telephone";
import CarFrontFill from "../assets/img/car-front-fill.svg";
import ShieldFill from "../assets/img/shield-lock-fill.svg";
import TelephoneFill from "../assets/img/telephone-fill.svg";
import CoinFill from "../assets/img/coin-fill.svg";
import Modal from "../components/Modal";
import Button from "../components/Button";
import SimModal from "../assets/img/sim-modal.png";
import { HeadersContext } from "../HeadersContext";
const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { slider } = useContext(HeadersContext);


  const pysElements = [
    {
      title: "Préstamos Personales",
      description:
        "Pedí el tuyo 100% online en pocos pasos y devolvelo en cuotas fijas.",
      buttonText: "Simular préstamo",
      icon: <Coin />,
      hoverIcon: CoinFill,
      colorScheme: "blue",
      link: "/prestamos",
    },
    {
      title: "Préstamos Prendarios",
      description:
        "Comprá o cambiá tu vehículo con las mejores opciones de financiación.",
      buttonText: "Conocé más",
      icon: <CarFront />,
      hoverIcon: CarFrontFill,
      colorScheme: "red",
      link: () => setIsModalOpen(true),
    },
    {
      title: "Seguros",
      description:
        "Cotizá, compará y emití 100% online la cobertura ideal para tu vehículo.",
      buttonText: "Conseguí el tuyo",
      icon: <Shield />,
      hoverIcon: ShieldFill,
      colorScheme: "green",
      link: "/seguros",
    },
    {
      title: "Asistencias",
      description:
        "Contratá tu asistencia de forma online y contá con beneficios las 24 hs. ",
      buttonText: "Contratá acá",
      hoverIcon: TelephoneFill,
      icon: <Telephone />,
      colorScheme: "yellow",
      link: "/asistencias",
    },
  ];

  return (
    <>
      <HeaderSlider hsElements={slider} />
      <ProductosYServicios elements={pysElements} />
      <Adherir />
      <Prestamos />
      <Reviews />
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="sim-modal-open">
          <div className="sim-modal-img">
            <img src={SimModal} alt="" />
          </div>
          <div className="sim-modal-text">
            <h5>
              Mirá todo lo que podés hacer con un Súper Préstamo Prendario
            </h5>
            <span>
              Te llevaremos al sitio de Santander para que puedas sacar tu
              préstamo
            </span>
            <p>
              Comprá o cambiá tu vehículo con las mejores opciones de
              financiación. Podés aprovechar los múltiples beneficios y
              préstamos que tenemos para ofrecerte. Alcanzar tus metas ¡nunca
              fue tan sencillo!
            </p>
            <Button
              variant={"filled-red"}
              onClick={() =>
                (window.location.href =
                  "https://www.santander.com.ar/personas/prendarios/prestamos-prendarios")
              }
            >
              Conseguí el tuyo
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Home;
