import React, { useState } from "react";
import Button from "../Button";
import Container from "../Container";
import Swal from "sweetalert2";
const TextInput = ({ name, handler, placeholder }) => {
  return (
    <input
      type="text"
      placeholder={placeholder}
      name={name}
      onChange={handler}
    />
  );
};

const Form = ({ title, subtitle, fields }) => {
  const [form, setForm] = useState(
    fields.reduce((acc, curr) => ((acc[curr.name] = ""), acc), {})
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await fetch(
      "https://santander-consumer-v2-e0c89957455a.herokuapp.com/api/contactos/register",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(form),
      }
    );

    if (res.ok) {
      Swal.fire({
        title: "Envio exitoso!",
        text: "En breve nos podremos en contacto",
        icon: "success",
        confirmButtonColor: "#ec0000",
      });
    } else {
      Swal.fire({
        title: "Error",
        text: "Hubo un error enviando el formulario. Intentelo de nuevo mas tarde.",
        icon: "error",
        confirmButtonColor: "#ec0000",
      });
    }

    document.getElementById("formulario-contacto").reset();
  };

  return (
    <div className="form" id="formulario">
      <Container>
        <h1>{title}</h1>
        <p>{subtitle}</p>
        <form
          className="form-fields"
          onSubmit={(e) => handleSubmit(e)}
          id="formulario-contacto"
        >
          {fields.map((field, i) => (
            <TextInput
              name={field.name}
              placeholder={field.placeholder}
              key={i}
              handler={(e) =>
                setForm((prevState) => ({
                  ...prevState,
                  [field.name]: e.target.value,
                }))
              }
            />
          ))}
          <Button variant={"filled-red"} type="submit">
            Enviar
          </Button>
        </form>
      </Container>
    </div>
  );
};

export default Form;
