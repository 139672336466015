import React from "react";
import Container from "./Container";
import Logo from "../assets/img/logo-footer.svg";
import Fe1 from "../assets/img/fe-1.png";
import Fe2 from "../assets/img/fe-2.png";
import Fe3 from "../assets/img/fe-3.png";

import People from "../assets/img/people.svg";
import Shop from "../assets/img/shop.svg";
import Globe from "../assets/img/globe.svg";
import LogoChico from "../assets/img/logo-chico.svg";
import Facebook from "../assets/img/facebook.svg";
import Linkedin from "../assets/img/linkedin.svg";
import Instagram from "../assets/img/instagram.svg";

const Footer = () => {
  return (
    <footer>
      <Container>
        <div className="footer-flex">
          <div className="footer-head">
            {window.innerWidth > 768 ? (
              <>
                <h4>Canales de comunicación</h4>
                <img src={Logo} alt="" />
              </>
            ) : (
              <>
                <img src={Logo} alt="" />
                <h4>Canales de comunicación</h4>
              </>
            )}
          </div>
          <div className="footer-info">
            <div className="clientes">
              <div className="icono-texto">
                <img src={People} alt="" />
                <span>Clientes</span>
              </div>
              <div className="una-info">
                <b>0800-999-1564 </b>
                <span>De lunes a sábados de 9 a 21h (opción 1)</span>
              </div>
              <div className="una-info">
                <b>11 6942-0968</b>
                <span>Atención las 24 horas los 365 días del año</span>
              </div>
              <div className="una-info">
                <b>Contacto@todoencuotas.com</b>
                <span>De lunes a viernes de 9 a 21h</span>
              </div>
            </div>
            <div className="comercios">
              <div className="icono-texto">
                <img src={Shop} alt="" />
                <span>Comercios</span>
              </div>
              <div className="una-info">
                <b>0800-999-1564 </b>
                <span>De lunes a sábados de 9 a 21h (opción 2)</span>
              </div>
              <div className="una-info">
                <b>11 6958-0056</b>
                <span>De lunes a sábados de 9 a 21h</span>
              </div>
              <div className="una-info">
                <b>Consumosc@santander.com.ar</b>
                <span>De lunes a viernes de 9 a 21h</span>
              </div>
            </div>
            <div className="redes">
              <div className="icono-texto">
                <img src={Globe} alt="" />
                <span>Redes sociales</span>
              </div>
              <a className="red" href="https://www.santander.com/es/home">
                <img src={LogoChico} alt="" />
                <p>www.santander.com</p>
              </a>
              <a
                className="red"
                href="https://www.facebook.com/profile.php?id=61558786637813&locale=es_LA"
              >
                <img src={Facebook} alt="" />
                <p>Santander Consumer Argentina</p>
              </a>
              <a
                className="red"
                href="https://www.linkedin.com/company/santander-consumer-argentina/"
              >
                <img src={Linkedin} alt="" />
                <p>Santander Consumer Argentina</p>
              </a>
              <a
                className="red"
                href="https://www.instagram.com/santanderconsumer_ar?igsh=MTZxY2lhd3IzZXcwaw=="
              >
                <img src={Instagram} alt="" />
                <p>@santanderconsumer_ar</p>
              </a>
            </div>
          </div>
          <div className="footer-line"></div>
          <div className="footer-bajada">
            <p>
              Santander Consumer S.A. CUIT 30-64922210-6, con domicilio en Av.
              Juan de Garay N° 151 Piso 9, C.A.B.A., Argentina, no es una
              entidad financiera y realiza sus operaciones con fondos propios.
              Ni otras entidades ni sus socios que utilicen las marcas Santander
              o Santander Consumer responden por las operaciones de Santander
              Consumer S.A. por ser esta última una sociedad independiente.
            </p>
          </div>
          <div className="footer-end">
            <img src={Fe1} alt="" />
            <img src={Fe2} alt="" />
            <img src={Fe3} alt="" />
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
