import React from "react";
import Pagofacil from "../../assets/img/pagofacil.png";
import Rapipago from "../../assets/img/rapipago.png";
import CobroExpensas from "../../assets/img/cobroexpensas.png";
import PagoMisCuentas from "../../assets/img/pagomiscuentas.png";
import Wallet from "../../assets/img/wallet.png";
import Container from "../Container";
const MetodosDePago = () => {
  return (
    <Container>
      <div className="metodos-de-pago">
        <div className="mdp-content">
          <h1>Además, podés pagarlo de la manera más fácil</h1>
          <p>
            Te mostramos los medios de pago presencial y online con los que
            contamos.
          </p>
          <div className="metodos-flex">
            <img src={Pagofacil} alt="" />
            <img src={Rapipago} alt="" />
            <img src={CobroExpensas} alt="" />
            <img src={PagoMisCuentas} alt="" />
          </div>
        </div>
        <img src={Wallet} alt="" className="wallet" />
      </div>
    </Container>
  );
};

export default MetodosDePago;
