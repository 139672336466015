export const fadeInVariants = {
  hidden: { opacity: 0, transition: { duration: 0.5 } },
  visible: { opacity: 1, transition: { duration: 0.5 } },
  exit: { opacity: 0, transition: { duration: 0.5 } },
};
export const fadeInVariantsLong = {
  hidden: { opacity: 0.3 },
  visible: {
    opacity: 1,
    transition: { duration: 0.8, ease: "linear" },
  },
  exit: { opacity: 0, transition: { duration: 2 } },
};
