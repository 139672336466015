import React, { useRef, useState } from "react";

const Scrollable = ({
  children,
  arrowLeft,
  arrowRight,
  arrowOffset,
  mobileArrowOffset,
}) => {
  const ourRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const mouseCoords = useRef({
    startX: 0,
    startY: 0,
    scrollLeft: 0,
    scrollTop: 0,
  });

  const handleDragStart = (e) => {
    if (!ourRef.current) return;
    const slider = ourRef.current.children[0];
    const startX = e.pageX - slider.offsetLeft;
    const startY = e.pageY - slider.offsetTop;
    const scrollLeft = slider.scrollLeft;
    const scrollTop = slider.scrollTop;
    mouseCoords.current = { startX, startY, scrollLeft, scrollTop };
    setIsMouseDown(true);
    document.body.style.cursor = "grabbing";
  };

  const handleDragEnd = () => {
    setIsMouseDown(false);
    if (!ourRef.current) return;
    document.body.style.cursor = "default";
  };

  const handleDrag = (e) => {
    if (!isMouseDown || !ourRef.current) return;
    e.preventDefault();
    const slider = ourRef.current.children[0];
    const x = e.pageX - slider.offsetLeft;
    const y = e.pageY - slider.offsetTop;
    const walkX = (x - mouseCoords.current.startX) * 1.5;
    const walkY = (y - mouseCoords.current.startY) * 1.5;
    slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
    slider.scrollTop = mouseCoords.current.scrollTop - walkY;
  };

  const handleArrows = (e, isLeft) => {
    if (!arrowOffset || !mobileArrowOffset) return;

    const slider = ourRef.current.children[0];
    if (window.innerWidth > 768) {
      if (isLeft) {
        slider.scrollLeft -= arrowOffset;
      } else {
        slider.scrollLeft += arrowOffset;
      }
    } else {
      if (isLeft) {
        slider.scrollLeft -= mobileArrowOffset;
      } else {
        slider.scrollLeft += mobileArrowOffset;
      }
    }
  };

  return (
    <div className="scrollable-wrapper">
      {arrowLeft &&
        React.cloneElement(arrowLeft, {
          style: { ...arrowLeft.props.style },
          className: arrowLeft.props.className,
          onClick: (e) => handleArrows(e, true),
        })}
      <div
        ref={ourRef}
        onMouseDown={handleDragStart}
        onMouseUp={handleDragEnd}
        onMouseMove={handleDrag}
        className="scrollable"
      >
        {children}
      </div>
      {arrowRight &&
        React.cloneElement(arrowRight, {
          style: { ...arrowRight.props.style },
          className: arrowRight.props.className,
          onClick: (e) => handleArrows(e, false),
        })}
    </div>
  );
};

export default Scrollable;
