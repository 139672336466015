import React from "react";
import Car1 from "../../assets/img/car1.svg";
import Car2 from "../../assets/img/car2.svg";
import Car3 from "../../assets/img/car3.svg";
import Container from "../Container";

const UnaCaracteristica = ({ title, description, icon }) => {
  return (
    <div className="car-box">
      <div className="red-stripe">
        <img src={icon} alt="" />
      </div>
      <h6>{title}</h6>
      <p>{description}</p>
    </div>
  );
};

const Caracteristicas = () => {
  const carBoxes = [
    {
      title: "Agilidad",
      description:
        "Somos veloces y ágiles, lo que nos permite adaptarnos rápidamente a las necesidades de nuestros clientes.",
      icon: Car1,
    },
    {
      title: "Confianza",
      description:
        "Creemos, damos oportunidades. Acompañamos a todas las personas a cumplir sus sueños.",
      icon: Car2,
    },
    {
      title: "Superación",
      description:
        "Exploramos y nos desafiamos, buscamos soluciones innovadoras para mejorar continuamente.",
      icon: Car3,
    },
  ];
  return (
    <div className="vertical-spacing caracteristicas">
      <Container>
        <div className="car-title">
          <h4>Porque sabemos que no estamos solos…</h4>
          <p>
            En Santander Consumer nos desafiamos y animamos a ir por más porque
            tenemos un equipo que nos sostiene y nos empuja, basado en nuestros
            3 valores
          </p>
        </div>

        <div className="car-flex">
          {carBoxes.map((car, i) => (
            <UnaCaracteristica
              title={car.title}
              description={car.description}
              icon={car.icon}
              key={i}
            />
          ))}
        </div>
      </Container>
    </div>
  );
};

export default Caracteristicas;
