import React from "react";
import { motion } from "framer-motion";
import { BubbleVariants } from "../../animations/bubbleVariants";
const BubbleShow = ({ children, inView, delay }) => {
  return (
    <motion.div
      variants={BubbleVariants}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      transition={{ delay, duration: 0.5, ease: "easeInOut" }}
    >
      {children}
    </motion.div>
  );
};

export default BubbleShow;
