import React from "react";
import Button from "./Button";
import Close from "../assets/img/cross.svg";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import FadeAnimation from "./Animations/FadeAnimation";
import StaggeredParagraphs from "./Animations/StaggeredParagraphs";

const MobileMenu = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const handleLink = (to) => {
    navigate(to);
    onClose();
  };

  return (
    <motion.div
      initial={{ height: 0, width: "0vw", opacity: 0 }}
      animate={{ height: "100vh", width: "100vw", opacity: 1 }}
      exit={{ height: 0, opacity: 0, width: "50vw" }}
      transition={{ duration: 0.4 }}
      className={`mobile-menu ${isOpen ? "open" : ""}`}
    >
      <motion.img
        initial={{ rotate: 0 }}
        animate={{ rotate: 360 }}
        src={Close}
        alt=""
        className="close"
        onClick={onClose}
      />
      <FadeAnimation delay={0.3} staggerDuration={0.2} className="menu-links">
        <StaggeredParagraphs>
          <span onClick={() => handleLink("/")}>Home</span>
          <span onClick={() => handleLink("/prestamos")}>
            Préstamos personales
          </span>
          <span onClick={() => handleLink("/prestamos")}>
            Préstamos prendarios
          </span>
          <span onClick={() => handleLink("/seguros")}>Seguros</span>
          <span onClick={() => handleLink("/asistencias")}>Asistencias</span>
          <span onClick={() => handleLink("/quienes-somos")}>
            Quienes somos
          </span>
        </StaggeredParagraphs>
      </FadeAnimation>
      <div className="hline"></div>
      <FadeAnimation
        staggerDuration={0.4}
        duration={0.9}
        delay={0.8}
        className="m-btn-group"
      >
        <Button variant={"outline-red-small"}>Portal Comercio</Button>
        <Button
          variant={"filled-red-small"}
          onClick={() => handleLink("/adherir-comercio")}
        >
          Adherí tu comercio
        </Button>
      </FadeAnimation>
    </motion.div>
  );
};

export default MobileMenu;
