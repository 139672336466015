import { AnimatePresence } from "framer-motion";
import Loading from "./pages/Loading";

const { createContext, useState, useEffect } = require("react");

export const HeadersContext = createContext({
  headers: [],
  slider: [],
});

export const HeaderContextProvider = ({ children }) => {
  const [headers, setHeaders] = useState([]);
  const [slider, setSlider] = useState([]);
  const [isHeadersLoading, setIsHeadersLoading] = useState(true);
  const [isSlidersLoading, setIsSlidersLoading] = useState(true);
  useEffect(() => {
    const getHeaders = async () => {
      const res = await fetch(
        "https://santander-consumer-v2-e0c89957455a.herokuapp.com/api/headers"
      );
      const json = await res.json();

      return json;
    };

    const getHeaderSliders = async () => {
      const res = await fetch(
        "https://santander-consumer-v2-e0c89957455a.herokuapp.com/api/sliders/list"
      );

      const json = await res.json();
      return json;
    };

    getHeaderSliders()
      .then((json) => setSlider(json))
      .finally(() => setIsSlidersLoading(false));

    getHeaders()
      .then((json) => setHeaders(json))
      .finally(() => setIsHeadersLoading(false));
  }, []);

  if (isHeadersLoading || isSlidersLoading)
    return (
      <AnimatePresence mode="wait">
        <Loading isVisible={isHeadersLoading || isSlidersLoading} />
      </AnimatePresence>
    );

  return (
    <HeadersContext.Provider value={{ headers, slider }}>
      {children}
    </HeadersContext.Provider>
  );
};
