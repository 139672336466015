import React, { useEffect, useState } from "react";
import Container from "../Container";
import Button from "../Button";
import Modal from "../Modal";
import Graph from "../../assets/img/graph.png";
import QR from "../../assets/img/QR.png";
import Download from "../../assets/img/download.svg";
import Naranja from "../../assets/img/naranja.png";
const TyC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [footerData, setFooterData] = useState(null);
  useEffect(() => {
    const getFooterData = async () => {
      const res = await fetch(
        "https://santander-consumer-v2-e0c89957455a.herokuapp.com/api/footers/get-footer/Prestamos_Personales"
      );

      const json = await res.json();
      return json;
    };

    getFooterData().then((json) => setFooterData(json.texto));
  }, []);

  return (
    <>
      <div className="tyc">
        <Container>
          <div className="tyc-flex">
            <img src={Naranja} alt="" className="naranja" />
            <div
              className="footer-texto"
              dangerouslySetInnerHTML={{ __html: footerData }}
            >
              {/* <h4>
              Contratos de adhesión - Ley N° 24.240 de Defensa del Consumidor
            </h4>
            
            <p>
              Exclusivo para personas humanas. El otorgamiento del crédito se
              encuentra sujeto a evaluación crediticia y condiciones de
              contratación de Santander Consumer S.A. CUIT 30-64922210-6,
              domicilio en Av. Juan de Garay N° 151 Piso 9, C.A.B.A. Condiciones
              vigentes por el día de la fecha. Santander Consumer S.A. no es una
              entidad financiera y realiza sus operaciones con fondos propios.
              Ni otras entidades ni sus socios que utilicen la marca Santander
              responden por las operaciones de Santander Consumer S.A.
            </p>
            <p>
              En todos los casos, las tasas y el Costo Financiero Total (CFT)
              dependerán del análisis crediticio del solicitante, el plazo de
              financiación elegido y el canal de solicitud del préstamo y serán
              informados al momento de solicitar el préstamo y antes de su
              otorgamiento. Monto mínimo: $ 10.000. Plazo mínimo: 6 meses. Plazo
              máximo: 6 meses. Tasa Fija Efectiva Anual: mínima 542,54% y máxima
              887,74% - Costo Financiero Total calculado en TEA (CFTEA):
            </p>
            <h1 className="tyc-big">CFTEA mín 818,17% y máx 1419,46%</h1>
            <p>
              Tasa Nominal Anual: mínima 201% y máxima 252%. Sistema de
              amortización de capital: El importe de cada cuota será imputado a
              intereses, costas, accesorios e IVA sobre intereses y el remanente
              a capital. Comisión por cancelación total: 8%. Los valores
              incluyen impuestos.
            </p>

            <p>
              LA AGENCIA DE ACCESO A LA INFORMACIÓN PÚBLICA, en su carácter de
              Órgano de Control de la Ley N° 25.326, tiene la atribución de
              atender las denuncias y reclamos que interpongan quienes resulten
              afectados en sus derechos por incumplimiento de las normas
              vigentes en materia de protección de datos personales.
            </p>
            <p>
              La Ley N° 2.244 establece que las personas físicas o jurídicas que
              comercialicen, o presten servicios a consumidores y/o usuarios en
              el ámbito de la C.A.B.A. y posean página de internet, deberán
              agregar un enlace con la Dirección General de Defensa y Protección
              al Consumidor.
              <br /> <b>Subsecretaría de Defensa del Consumidor: </b>
              Para consultas y/o denuncias ingrese{" "}
              <a href="https://www.argentina.gob.ar/economia/industria-y-comercio/defensadelconsumidor">
                aquí.
              </a>
              <br />{" "}
              <b>Dirección General de Defensa y Protección al Consumidor: </b>
              Para consultas y/o denuncias ingrese{" "}
              <a href="https://buenosaires.gob.ar/gobierno/defensa-al-consumidor/denuncia-defensa-al-consumidor">
                aquí.
              </a>
            </p> */}
            </div>
            <div className="tyc-prestamos-buttons">
              <img src={QR} alt="" className="QR" />
              <Button variant={"outline-red"}>Botón de arrepentimiento</Button>
              <Button
                variant={"outline-red"}
                onClick={() => setIsModalOpen(true)}
              >
                Baja del servicio
              </Button>
              <a
                href="https://www.todoencuotas.com/assets/pdf/InformacionAlUsuarioFinanciero.pdf "
                className="icono-texto tyc-prestamos"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Download} alt="" />
                <span>Información al usuario financiero</span>
              </a>
            </div>
          </div>
        </Container>
      </div>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="m-prestamos">
          <img src={Graph} alt="" className="graph" />
          <div className="mp-text">
            <h4>Darme de baja del préstamo</h4>
            <h6>
              Para poder tramitar tu baja necesitamos que nos proporciones la
              siguiente información:
            </h6>
          </div>
          <div className="mp-form">
            <input type="text" placeholder="Nombre y apellido" />
            <div className="d-flex i-flex">
              <input type="text" placeholder="DNI" />
              <input type="text" placeholder="Email" />
            </div>
            <div className="cellphone-i">
              <p style={{ marginLeft: "11px", marginBottom: "8px" }}>
                Dejanos tu celular para poder contactarte
              </p>
              <div className="m-input-group">
                <span>+</span>
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="Cód. área"
                  style={{
                    width: `100%`,
                  }}
                />
                <span>11</span>
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="Número"
                  style={{
                    width: `100%`,
                  }}
                />
              </div>
              <div className="m-disclaimer">
                <h5>Dejanos un mensaje</h5>
                <p>
                  Te pedimos que nos cuentes por qué motivo estás realizando con
                  esta solicitud.
                </p>
              </div>
            </div>
          </div>
          <div className="m-button-group">
            <Button
              variant={"filled-red-small"}
              onClick={() => setIsModalOpen(false)}
            >
              Volver
            </Button>
            <Button variant={"outline-red-small"}>Enviar</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TyC;
