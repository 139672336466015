import React, { useEffect, useState } from "react";
import Container from "../Container";
import Button from "../Button";
import Bajada from "../../assets/img/tyc-bajada.png";

const TyC = () => {
  const [footerData, setFooterData] = useState("");
  useEffect(() => {
    const getFooterData = async () => {
      const res = await fetch(
        "https://santander-consumer-v2-e0c89957455a.herokuapp.com/api/footers/get-footer/Seguros"
      );

      const json = await res.json();
      return json;
    };

    getFooterData().then((json) => setFooterData(json.texto));
  }, []);
  return (
    <div className="tyc">
      <Container>
        <div className="tyc-flex">
          <div
            className="footer-texto"
            dangerouslySetInnerHTML={{ __html: footerData }}
          >
            {/* <h4>
              Consultá términos, alcances, exclusiones y condiciones de las
              coberturas Aqui.
            </h4>
            <p>
              Agente Institorio Santander Consumer S.A; Inscripto en el Registro
              de Agentes Institorios bajo el nro. 333, de conformidad con la
              Resolución SSN N° 38.052. Santander Consumer S.A. CUIT
              30-64922210-6, con domicilio en Av. Juan de Garay N° 151 Piso 9,
              C.A.B.A., registrada en la Inspección General de Justicia bajo el
              N° correlativo 1.547.045, no es una entidad financiera y realiza
              sus operaciones con fondos propios. Ni otras entidades ni sus
              socios que utilicen la marca Santander responden por sus
              operaciones. Los seguros son emitidos por las compañías
              aseguradoras. Consulte compañías aseguradoras de acuerdo al
              producto solicitado. Superintendencia de Seguros de Nación
              teléfono 0800-666-8400 y su página web www.argentina.gob.ar/ssn.
              Empresa Aseguradora: Zurich Argentina Compañía de Seguros SA (SSN
              N°0228) (aplica únicamente a Auto), Triunfo Cooperativa de Seguros
              Limitada (SSN N°402) , Caja de Seguros S.A (SSN N°501), Río
              Uruguay Cooperativa de Seguros Limitada (SSN N°0360). Usted
              dispone de los siguientes canales a los fines de revocar la
              aceptación o finalizar su relación contractual con los seguros a
              través de https://www.santanderconsumer.com.ar ingresando en la
              opción Botón de Baja y/o Botón de Arrepentimiento. Usted conoce y
              acepta que Santander Consumer, según su política corporativa: 1)
              no opera con personas que se encuentren en listas de sanciones
              internacionales 2) no realiza operaciones directas o indirectas
              con determinados países/territorios y que podrá consultar el
              listado actualizado de países/territorios con el centro de
              atención al cliente de Santander Consumer; por ende, se compromete
              a no canalizar o facilitar transacciones con estos países a través
              de mis productos en Santander Consumer.
            </p> */}
          </div>
          <div className="tyc-marcas">
            <img src={Bajada} alt="" />
            <div className="hline"></div>
            <span>N° de inscripción en SSN 333</span>
            <div className="hline"></div>
            <span>Atención al asegurado 0800-666-8400</span>
            <div className="hline"></div>
            <span>Organismo de control www.argentina.gob.ar/ssn</span>
          </div>
          <div className="tyc-buttons">
            <Button
              variant={"outline-red"}
              onClick={() =>
                (window.location.href =
                  "https://seguros.santanderconsumer.com.ar/arrepentimiento")
              }
            >
              Botón de arrepentimiento
            </Button>
            <Button
              variant={"outline-red"}
              onClick={() =>
                (window.location.href =
                  "https://seguros.santanderconsumer.com.ar/baja")
              }
            >
              Baja del servicio
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default TyC;
