import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import AdherirComercio from "./pages/AdherirComercio";
import Layout from "./pages/Layout";
import Seguros from "./pages/Seguros";
import Prestamos from "./pages/Prestamos";
import Asistencias from "./pages/Asistencias";
import QuienesSomos from "./pages/QuienesSomos";
import Home from "./pages/Home";
export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="/adherir-comercio" element={<AdherirComercio />} />
        <Route path="/seguros" element={<Seguros />} />
        <Route path="/prestamos" element={<Prestamos />} />
        <Route path="/asistencias" element={<Asistencias />} />
        <Route path="/quienes-somos" element={<QuienesSomos />} />
      </Route>
    </>
  )
);
