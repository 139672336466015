import React from "react";
import Button from "../Button";
import Container from "../Container";
const TyC = () => {
  return (
    <div className="tyc">
      <Container>
        <div className="tyc-flex">
          <h4>
            En caso de requerir asesoramiento sobre los productos, realizar un
            reclamo o solicitar una baja ya contratada comunicate con red S.O.S
            S.A al 0800-444-4647
          </h4>
          <p>
            Servicio de asistencias prestados por S.O.S S.A CUIT 30-65755934-9,
            Luna 49 - CABA. Santander Consumer S.A CUIT 30-64922210-6, con
            domicilio en Av. Juan de Garay 151, 9° Piso - CABA, Argentina; no es
            una entidad financiera y realiza sus operaciones con fondos propios.
            Ni otras entidades ni sus socios que utilicen las marcas Santander o
            Santander Consumer responden por las operaciones de DSantander
            Consumer S.A por ser esta última una sociedad independiente.{" "}
          </p>
          <div className="tyc-asistencias-buttons">
            <Button variant={"outline-red"} onClick={() => window.location.href = "https://asistenciasyseguros.santanderconsumer.com.ar/asistencias/arrepentimiento"}>Botón de arrepentimiento</Button>
            <Button variant={"outline-red"} onClick={() => window.location.href = "https://asistenciasyseguros.santanderconsumer.com.ar/asistencias/baja"}>Baja del servicio</Button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default TyC;
