import React from "react";

const Coin = ({ fill = "" }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="coin">
        <g id="Vector">
          <path
            d="M8.58337 14.2665C8.69782 15.6968 9.82702 16.8117 11.8565 16.9438V18H12.7568V16.9364C14.855 16.7897 16.0834 15.6675 16.0834 14.0391C16.0834 12.5575 15.1449 11.7946 13.4664 11.3985L12.7568 11.2298V8.35452C13.6571 8.45721 14.2294 8.94866 14.3667 9.63081H15.946C15.8316 8.25183 14.649 7.17359 12.7568 7.05623V6H11.8565V7.07824C10.0635 7.25428 8.84278 8.33252 8.84278 9.85819C8.84278 11.2078 9.75072 12.066 11.2614 12.4181L11.8565 12.5648V15.6161C10.9333 15.4768 10.3229 14.9707 10.1856 14.2665H8.58337ZM11.8489 11.0171C10.9638 10.8117 10.4832 10.3936 10.4832 9.76284C10.4832 9.05868 11.002 8.53056 11.8565 8.37653V11.0171H11.8489ZM12.8865 12.8068C13.9623 13.0562 14.4582 13.4597 14.4582 14.1712C14.4582 14.9853 13.8402 15.5428 12.7568 15.6455V12.7775L12.8865 12.8068Z"
            fill={`${fill || "#1BB3BC"}`}
          />
          <path
            d="M12.3334 22.5C6.53438 22.5 1.83337 17.799 1.83337 12C1.83337 6.20101 6.53438 1.5 12.3334 1.5C18.1324 1.5 22.8334 6.20101 22.8334 12C22.8334 17.799 18.1324 22.5 12.3334 22.5ZM12.3334 24C18.9608 24 24.3334 18.6274 24.3334 12C24.3334 5.37258 18.9608 0 12.3334 0C5.70596 0 0.333374 5.37258 0.333374 12C0.333374 18.6274 5.70596 24 12.3334 24Z"
            fill={`${fill || "#1BB3BC"}`}
          />
          <path
            d="M12.3334 20.25C7.77702 20.25 4.08337 16.5563 4.08337 12C4.08337 7.44365 7.77702 3.75 12.3334 3.75C16.8897 3.75 20.5834 7.44365 20.5834 12C20.5834 16.5563 16.8897 20.25 12.3334 20.25ZM12.3334 21C17.3039 21 21.3334 16.9706 21.3334 12C21.3334 7.02944 17.3039 3 12.3334 3C7.36281 3 3.33337 7.02944 3.33337 12C3.33337 16.9706 7.36281 21 12.3334 21Z"
            fill={`${fill || "#1BB3BC"}`}
          />
        </g>
      </g>
    </svg>
  );
};

export default Coin;
