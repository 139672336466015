import React from "react";

const Telephone = ({ fill = "" }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="telephone">
        <path
          id="telephone_2"
          d="M6.14744 1.99274C5.77177 1.50974 5.0578 1.46529 4.62513 1.89796L3.07425 3.44884C2.34903 4.17406 2.08243 5.20148 2.39928 6.1027C3.67169 9.72186 5.75485 13.1201 8.65071 16.0159C11.5466 18.9118 14.9448 20.9949 18.5639 22.2673C19.4651 22.5842 20.4926 22.3176 21.2178 21.5924L22.7687 20.0415C23.2013 19.6088 23.1569 18.8949 22.6739 18.5192L19.2146 15.8286C18.9679 15.6367 18.6466 15.5689 18.3433 15.6447L15.0597 16.4656C14.1678 16.6886 13.2244 16.4273 12.5743 15.7772L8.88943 12.0923C8.23936 11.4423 7.97803 10.4988 8.20101 9.60689L9.02191 6.32329C9.09772 6.02004 9.0299 5.69876 8.83799 5.45202L6.14744 1.99274ZM3.49378 0.766614C4.60696 -0.346558 6.44387 -0.232196 7.41037 1.01045L10.1009 4.46974C10.5947 5.10456 10.7692 5.93113 10.5741 6.71134L9.7532 9.99494C9.66654 10.3416 9.76811 10.7083 10.0208 10.961L13.7056 14.6459C13.9583 14.8985 14.325 15.0001 14.6717 14.9134L17.9553 14.0925C18.7355 13.8975 19.5621 14.072 20.1969 14.5657L23.6562 17.2563C24.8988 18.2228 25.0132 20.0597 23.9 21.1728L22.3491 22.7237C21.2395 23.8333 19.5799 24.3205 18.0333 23.7767C14.1929 22.4266 10.5882 20.2161 7.51936 17.1473C4.45052 14.0784 2.24005 10.4737 0.889883 6.63337C0.346102 5.08669 0.833321 3.42708 1.9429 2.3175L3.49378 0.766614Z"
          fill={`${fill || "#E9BA2E"}`}
        />
      </g>
    </svg>
  );
};

export default Telephone;
