import React from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
const Layout = () => {
  return (
    <>
      <Navigation />
      <main>
        <ScrollRestoration />
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export default Layout;
